import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

// Components
import Layout from '../components/Layout'
import PageTitle from '../components/Layout/PageTitle'
import Pagination from '../components/Layout/Pagination'
import TrackPreview from '../components/Track/TrackPreview/'

const SmallLayouts = ({ location, data, pageContext }) => {
  const { currentPage, numPages, name } = pageContext
  const posts = data?.allMdx?.nodes
  const defaultImg = data?.file

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/${name}` : `/${name}/${(currentPage - 1).toString()}`
  const nextPage = `/${name}/${(currentPage + 1).toString()}`

  return (
    <>
      <Layout location={location} title="Small Layouts">
        <main>
          <PageTitle
            title={'Small Layouts'}
            is404={false}
            breadArray={[
              { name: 'Small Layouts', href: 'small-layouts' },
              { name: `page ${currentPage}`, href: '' },
            ]}
          />
          <section className="container">
            <div className="pb-lg-5">
              <div className="row">
                {posts.map(({ id, frontmatter }) => {
                  const { title, slug, trackBrand, featureImg, width, height } = frontmatter
                  let img = defaultImg,
                    isFeature = false
                  if (featureImg !== null) {
                    img = featureImg
                    isFeature = true
                  }

                  return (
                    <article key={id} className="col-12 col-sm-6 col-xl-4 mb-4">
                      <TrackPreview
                        title={title}
                        slug={`/${slug}`}
                        img={img}
                        brand={trackBrand}
                        isFeature={isFeature}
                        width={width}
                        height={height}
                      />
                    </article>
                  )
                })}
              </div>
            </div>
            {isFirst !== true && isLast !== true && (
              <Pagination
                isFirst={isFirst}
                isLast={isLast}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            )}
          </section>
        </main>
      </Layout>
    </>
  )
}

SmallLayouts.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default SmallLayouts

export const blogQuery = graphql`
  query smallLayoutQuery($skip: Int!, $limit: Int!) {
    file(relativePath: { eq: "blog-post.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/smallLayout/" } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          trackBrand
          width
          height
          featureImg {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
